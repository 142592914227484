<template>
  <div class="withdrawalRecord_page">
    <van-loading v-if="mLoading" type="spinner" color="#FF006E" vertical>加载中...</van-loading>
    <div class="commission_list" v-if="!mLoading">
      <van-list v-model="moreLoading" :finished="finished" offset="50" finished-text="没有更多了" @load="upMoreLoad"
        v-if="noteList && noteList.length > 0">
        <div class="item_box" v-for="(item) in noteList" :key="item.id">
          <div class="itemlog">
            <div class="left">
              <div class="logo_box">
                <img v-if="item.paymentType == 1" src="@/sprite/bank_card.png">
                <img v-if="item.paymentType == 2" src="@/sprite/alipay.png">
              </div>
              <div class="details">
                <p>提现状态：<span :class="colorClass(item)">{{ statusTip(item) }}</span></p>
                <p>{{ item.applyTime }}</p>
              </div>
            </div>
            <div class="right">
              <span>¥</span><span>{{ item.amount || 0 }}</span>
            </div>
          </div>
          <div class="reason" v-if="item.withdrawalStatus == 2 || item.withdrawalStatus == 5">
            提现失败原因：{{ item.remark }}，如有疑问，可联系客服，感谢理解！</div>
        </div>
      </van-list>
      <div class="noData" v-if="noteList.length === 0"></div>
    </div>
  </div>
</template>
<script>
import {
  getToken,
  removeToken,
  setToken,
  sessionGet,
  sessionRemove,
  sessionSet,
  isAlipayOrWechat,
  splitEndGetToken,
  splitEndJudgeToken,
  urlParse,
} from '@/utils/tokenUtils'
import './index.less'
import { GetUserCashApplyPageList } from '@/service/withdraw'
export default {
  data() {
    return {
      mid: getToken('mid'),
      mgUuid: getToken('mgUuid'),
      mLoading: false,
      moreLoading: false,
      finished: false,
      noteList: [
        // { paymentType: '1', withdrawalStatus: 0, applyTime: '2022-07-14 18:25:33' },
        // { paymentType: '2', withdrawalStatus: 1, applyTime: '2022-07-14 18:25:33' },
        // { paymentType: '2', withdrawalStatus: 2, applyTime: '2022-07-14 18:25:33' },
        // { paymentType: '1', withdrawalStatus: 3, applyTime: '2022-07-14 18:25:33' },
        // { paymentType: '1', withdrawalStatus: 4, applyTime: '2022-07-14 18:25:33' },
        // { paymentType: '1', withdrawalStatus: 5, applyTime: '2022-07-14 18:25:33' }
      ], // 提现记录数据
      pageCurrent: 1,
      pageSize: 15
    }
  },
  computed: {
    // 提现状态中文转化
    statusTip() {
      return (row) => {
        // console.log(row, 'row')
        switch (row.withdrawalStatus) {
          case 0:
            return '待审核'
          case 1:
            return '提现中'
          case 2:
            return '提现失败'
          case 3:
            return '提现中'
          case 4:
            return '提现成功'
          case 5:
            return '提现失败'
        }
      }
    },
    colorClass() {
      return (row) => {
        switch (row.withdrawalStatus) {
          case 0:
            return 'color_class_0'
          case 1:
            return 'color_class_1'
          case 2:
            return 'color_class_2'
          case 3:
            return 'color_class_3'
          case 4:
            return 'color_class_4'
          case 5:
            return 'color_class_5'
        }
      }
    }
  },
  created() { },
  mounted() {
    setTimeout(() => {
      // 埋点，会员数据上报（页面PV）
      let token = localStorage.getItem('token')
      // console.log('token', token)
      let mgUuid = token ? this.mgUuid : ''
    }, 200)
    this.init()
  },
  methods: {
    init() {
      this.upMoreLoad()
    },
    // 提现记录数据
    getUserCashApplyPageList() {
      this.mLoading = true
      const idx = this.pageCurrent
      let params = {
        pageIndex: this.pageCurrent,
        pageSize: this.pageSize
      }
      GetUserCashApplyPageList(params).then((res) => {
        const { code, data, message } = res
        if (code === '0') {
          this.mLoading = false
          this.moreLoading = false
          if (idx > 1) {
            this.noteList = [...this.noteList, ...data.list]
          } else {
            this.noteList = data.list
          }
          if (data && this.noteList.length == data.total) {
            this.finished = true
          } else {
            this.finished = false
          }
          // console.log('this.noteList', this.noteList)
        } else {
          this.mLoading = false
          this.$toast(message)
        }
      })
    },
    // 上滑加载更多
    upMoreLoad() {
      this.moreLoading = true
      this.getUserCashApplyPageList()
      this.pageCurrent += 1
    }
  }
}
</script>
<style lang="less" scoped></style>